<template>
    <div class="container">
        <iframe class="iframe-offer" src="https://zqoc.2track.info/" frameBorder="0"></iframe>
    </div>
</template>

<script>
  export default {
    name: 'DesktopLocker',
    components: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .container {
      height: 100%;
      width: 100%;
      display: flex;
      margin: 0 auto;
    }
    .iframe-offer {
      display: flex;
      flex: 1 auto;
      height: 100%;
    }
</style>
