<template>
    <div id="app">
        <div class="site-logo">
            <img class="site-logo-img" :src="logo"/>
        </div>
        <div class="content-wrapper">
            <router-view/>
        </div>
        <div class="footer">
            <div class="footer-text">&copy; 2022</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'app',
    components: {},
    data() {
        return {
            percentage: 0,
            showDialog: false,
        }
    },
    mounted() {
        let username = this.$route.query.username;
        let subid = this.$route.query.subid;
        let site = this.$route.query.site;

        if (!username) {
            this.$store.dispatch('OPEN_OFFER_PAGE')
        } else {
            this.$store.dispatch('LOAD_LP', {
                username: username,
                subid: subid,
                site: site
            })
        }
    },
    computed: {
        userNameExists() {
            return this.$store.state.username
        },
        loading() {
            return this.$store.state.loading
        },
        logo() {
            let site = this.$route.query.site;
            if (site !== undefined) {
                return `https://${site}/img/logo.png`;
            }
            return "/logo.svg"
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
    },
    methods: {}
}

</script>
<style lang="scss">
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("default", (
    primary: md-get-palette-color(blue, A200), // The primary color of your application
    accent: md-get-palette-color(red, A200) // The accent or secondary color
));

@import "~vue-material/dist/theme/all"; // Apply the theme
html, body {
    padding: 0;
    margin: 0;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

.site-logo {
    margin: 10px 0 20px 0;

    .site-logo-img {
        display: block;
        margin: 0 auto;
        width: 120px;
    }
}

.content-wrapper {
    margin: 5px auto 5px auto;
    width: 90%;
    display: block;
    background: white;
    max-width: 500px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 5px 5px 0 0;
}

@media screen and (min-width: 1200px) {
    .content-wrapper {
        max-width: 800px;
    }
}

.container {
    padding: 5px 10px;
}

/**
Custom styles
*/

.md-dialog {
    margin: 0 auto;
    overflow: visible;
    max-height: 100%;
    width: 100%;
    max-width: 800px;

    .md-dialog-container {
        max-width: 800px;
        overflow: visible;
        max-height: 100%;

        .md-dialog-content {
            overflow: visible;

        }
    }
}

</style>

