<template>
    <div v-if="isReady" class="container">
        <span class="md-title">View Leaked content of @{{ account.username }}</span>
        <p class="md-subheading">To view the leaks of @{{ account.username }} use one of the following options.</p>
        <md-divider></md-divider>
        <div class="content-center">
            <md-list class="method-list">
                <md-list-item class="method-list-item">
                    <md-icon class="md-primary method-list-icon">telegram</md-icon>
                    <span class="md-list-item-text md-body-2">Telegram</span>
                    <md-badge class="md-square md-primary mr-1" md-content="⚡ Fastest"></md-badge>
                    <md-badge class="md-square md-primary mr-1" md-content="100% Working"></md-badge>
                    <md-badge class="md-square" md-content="New"/>
                </md-list-item>
            </md-list>

            <span class="md-body-1">Generate <b>your own private key</b> first, then use our Telegram bot to get the leaks of @{{
                    account.username
                }}.</span>
            <div class="container-key">
                <md-button @click="openClipboard" v-if="!clipboardOpen" target="_blank"
                           class="md-raised md-primary md-button-no-margin">
                    <md-icon>key</md-icon>
                    Generate personal key
                </md-button>
                <md-progress-bar v-if="clipboardLoading" md-mode="indeterminate"
                                 class="progress-bar-captcha"></md-progress-bar>
                <div class="container-clipboard-btn" v-if="clipboardShow">
                    <Clipboard :text="clipboardContent"/>
                    <br/>
                    <md-button :href="telegramLink" target="_blank" class="md-raised md-primary md-btn">
                        <md-icon>telegram</md-icon>
                        Open Telegram
                    </md-button>
                    <br/>
                    <span class="md-caption">If above button doesn't work try adding <a
                        :href="telegramLink">@{{ telegramBotName }}</a> manually on Telegram!</span>
                </div>
            </div>
            <br/>
            <md-divider></md-divider>
            <md-list class="method-list">
                <md-list-item class="method-list-item">
                    <md-icon class="method-list-icon">web</md-icon>
                    <span class="md-list-item-text md-body-2">Web unlock (old)</span>
                    <md-badge class="md-square md-gray" md-content="Old"></md-badge>
                </md-list-item>
            </md-list>
            <span class="md-body-1">If you don't have Telegram you can use our old method to get leaks from OnlyFans. With this method you get a direct <b>mega.co.nz</b> link but first you need to complete <b>1</b> offer to get redirected to the download page.</span>
            <br/>
            <md-button :href="this.offerUrl()" target="_blank" class="md-raised md-button-no-margin">
                <md-icon class="md-icon-fix-locker">locker</md-icon>
                Open offer page
            </md-button>
            <br/>
            <p class="md-caption">This method is not 100% guaranteed to work, therefore we recommend you using our <b>newly</b>
                developed Telegram Bot!</p>
        </div>
    </div>
</template>


<script>
// @ is an alias to /src
import Locker from "@/components/Locker/Locker";
import OFGallery from "@/components/OFGallery";
import Clipboard from "../../components/Clipboard";

export default {
    name: 'mobileUserExistsLocker',
    components: {
        Clipboard,
        OFGallery,
        Locker
    },
    computed: {
        account() {
            return this.$store.state.account
        },
        username() {
            return this.$store.state.username
        },
        telegramLink() {
            // var sites = [
            //     'fansviewer.net',
            //     'leakfans.com',
            //     'leakedfans.org',
            //     'freefans.co'
            // ]
            //
            // if (sites.includes(this.$store.state.site)) {
            return "https://t.me/OF_image_bot";
            // }
            // return "https://t.me/Leaked_OF_bot"
        },
        telegramBotName() {
            // var sites = [
            //     'fansviewer.net',
            //     'leakfans.com',
            //     'leakedfans.org',
            //     'freefans.co'
            // ]
            //
            // if (sites.includes(this.$store.state.site)) {
            return "OF_image_bot";
            // }
            // return "Leaked_OF_bot"
        },
        site() {
            return this.$store.state.site
        },
        avatar() {
            return this.account.avatar;
        },
        postsCount() {
            let postsCount = this.account.postsCount
            if (postsCount === 0) {
                return this.photosCount + this.videosCount;
            }
            return postsCount;
        },
        isReady() {
            return !this.$store.state.loading && this.$store.state.account.userExists
        },
        clipboardContent() {
            let privateKey = btoa(`${this.username}|${this.site}`);
            return `OFKey:${privateKey}`
        }
    },
    data() {
        return {
            percentage: 0,
            showDialog: false,
            clipboardOpen: false,
            clipboardLoading: false,
            clipboardShow: false,
        }
    },
    methods: {
        toKeyPage() {
            let routeData = this.$router.resolve({name: 'notePad', params: {userId: btoa(this.$route.query.username)}});
            window.open(routeData.href, '_blank');
        },
        openClipboard() {
            this.clipboardOpen = true
            this.clipboardLoading = true
            var that = this
            setTimeout(function () {
                that.clipboardLoading = false
                that.clipboardShow = true
            }, 2000)
        },
        offerUrl() {
            return `https://downloadlocked.com/cl/i/ndpr1k?aff_sub4=${this.$route.query.site}&aff_sub5=${this.$route.query.username}`;
        }
    }
}

</script>
<style lang="scss">
.container {
    padding: 5px 10px;

    .profile-info-username {
        margin: 0;
    }
}

.md-icon-fix-locker {
    margin-right: -45px !important;
    width: auto !important;
}

.md-button-no-margin {
    margin: 10px 0 !important;
}

.container-clipboard-btn {
    margin: 10px 0;
}

p {
    margin: 10px 0;
}

.mr-1 {
    margin-right: 5px;
}

.method-list {
    margin: 0 !important;
    padding: 0;
    min-height: 30px;

    .md-list-item-content {
        padding: 0 !important;
    }

    .method-list-icon {
        margin: 0 5px 0 0 !important;
        padding: 0 !important;
    }
}

.md-gray {
    background-color: #888888 !important;
}

.loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    padding: 50px;

    .loading-inner {
        text-align: center;
    }
}

.footer {
    text-align: center;
    margin: 10px 0;
    display: block;

    .footer-text {
        font-style: italic;
        color: #888888;
    }
}

</style>
