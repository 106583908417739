<template>
    <div>
        <DesktopHome v-if="!this.isMobile"/>
        <MobileHome v-if="this.isMobile"/>
    </div>
</template>

<script>
// @ is an alias to /src
import DesktopHome from "../views/DesktopHome";
import MobileHome from "../views/MobileHome";

export default {
    name: 'home',
    components: {
        DesktopHome,
        MobileHome
    },
    computed: {
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
    },
    data() {
        return {

        }
    }
}

</script>
<style lang="scss">

.md-icon-fix-locker {
    margin-right: -45px !important;
    width: auto !important;
}

.md-button-no-margin {
    margin: 10px 0 !important;
}

.container-clipboard-btn {
    margin: 10px 0;
}

p {
    margin: 10px 0;
}

.mr-1 {
    margin-right: 5px;
}

.method-list {
    margin: 0 !important;
    padding: 0;
    min-height: 30px;

    .md-list-item-content {
        padding: 0 !important;
    }

    .method-list-icon {
        margin: 0 5px 0 0 !important;
        padding: 0 !important;
    }
}

.md-gray {
    background-color: #888888 !important;
}

.loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    padding: 50px;

    .loading-inner {
        text-align: center;
    }
}

.footer {
    text-align: center;
    margin: 10px 0;
    display: block;

    .footer-text {
        font-style: italic;
        color: #888888;
    }
}

</style>
