<template>
    <div>
        <div v-if="loading" class="loading">
            <div class="loading-inner">
                <md-progress-spinner md-mode="indeterminate" class="center"></md-progress-spinner>
                <h3>Downloading content...</h3>
            </div>
        </div>
        <div v-if="!loading">
            <div class="profile-container">
                <div class="profile-header"
                     v-bind:style="{ 'background-image': 'url(' + header + ')', 'background-size': 'cover' }">
                </div>
                <img class="profile-image" :src="avatar"/>
            </div>
            <div class="profile-info-container">
                <h1 class="profile-info-name">{{ account.name }}</h1>
                <h1 class="profile-info-username">@{{ account.username }}</h1>
                <div class="profile-info-media">
                    <ul class="profile-info-media-list">
                        <li class="profile-info-media-list-item">
                            <md-icon class="md-icon-media">image</md-icon>
                            {{ photosCount }}
                        </li>
                        <li class="profile-info-media-list-item">
                            <md-icon class="md-icon-media">movie</md-icon>
                            {{ videosCount }}
                        </li>
                    </ul>
                </div>
            </div>
            <md-divider></md-divider>
            <div class="gallery-content">
                <div class="gallery-overlay">
                    <h1 class="gallery-title">Download LEAKS {{ account.username }}</h1>
                    <h1 class="gallery-subtitle">Download all {{ account.postsCount }} photos and videos of {{
                            account.username
                        }}</h1>
                    <div class="container-button">
                        <md-button class="md-raised cta-btn" @click="locker" v-if="!loadingCaptcha">
                            <md-icon class="md-icon-media">download</md-icon>
                            <span class="cta-btn-text">Download for free</span>
                        </md-button>
                        <md-progress-bar v-if="loadingCaptcha" md-mode="indeterminate"
                                         class="progress-bar-captcha"></md-progress-bar>
                        <md-dialog :md-active.sync="showDialog" :md-close-on-esc="false"
                                   :md-click-outside-to-close="false" :md-fullscreen="false " class="dialog">
                            <md-dialog-title>Human Verification Required!</md-dialog-title>
                            <md-dialog-content>
                                To continue to <b>your download</b> you need to complete <b>2 offers</b> first on the
                                next page. <br/><br/>To go to the verify page click on <b>"Verify"</b>. After you're
                                done you will be redirected automatically to the download page.
                            </md-dialog-content>
                            <md-dialog-actions>
                                <md-button class="md-primary" @click="openLocker">Verify</md-button>
                            </md-dialog-actions>
                        </md-dialog>
                    </div>
                </div>
                <OFGallery/>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Locker from "@/components/Locker/Locker";
import OFGallery from "@/components/OFGallery";

export default {
    name: 'User',
    components: {
        OFGallery,
        Locker
    },
    computed: {
        account() {
            return this.$store.state.account
        },
        avatar() {
            return this.account.avatar;
        },
        header() {
            return this.account.header;
        },
        postsCount() {
            let postsCount = this.account.postsCount
            if (postsCount === 0) {
                return this.photosCount + this.videosCount;
            }
            return postsCount;
        },
        photosCount() {
            let photosCount = this.account.photosCount
            if (photosCount === 0) {
                return 56;
            }
            return photosCount;
        },
        loading() {
            return this.$store.state.loading
        },
        videosCount() {
            let videosCount = this.account.videosCount
            if (videosCount === 0) {
                return 31;
            }
            return videosCount;
        }
    },
    data() {
        return {
            percentage: 0,
            showDialog: false,
            loadingCaptcha: false
        }
    },
    mounted() {
        window.localStorage.setItem('subid', this.$route.query.subid);
        window.localStorage.setItem('site', this.$route.query.site);
        window.localStorage.setItem('username', this.$route.query.username);
    },

    methods: {
        locker: function (event) {
            this.loadingCaptcha = true;
            var that = this;
            setTimeout(function () {
                that.showDialog = true;
            }, 2000)
        },
        openLocker: function (event) {
            window.location = "https://verifyzone.net/cl/i/g69r71"
        }
    }
}

</script>
<style scoped lang="scss">
.md-content {
    padding: 0 16px 0px 16px;
}

.progress-bar-captcha {
    margin: 50px auto !important;
}

.profile-container {
    background: white;
    position: relative;
    margin-bottom: 60px;
    border-radius: 5px 5px 0 0;

    .profile-header {
        height: 100px;
        border-radius: 5px 5px 0 0;
    }

    .profile-image {
        position: absolute;
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        margin-top: -40px;
        display: block;
        z-index: 1;
        border-radius: 100%;
        border: 2px solid #ffffff;
        font-size: 36px;
    }
}

.loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    padding: 50px;

    .loading-inner {
        text-align: center;
    }
}

.profile-info-container {
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;

    .profile-info-name,
    .profile-info-username {
        font-size: 15px;
        margin: 0;
    }


    .profile-info-name {
    }

    .profile-info-username {
        font-weight: 400;
    }


    .profile-info-media {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2px;

        .profile-info-media-list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
            font-size: 12px;
            font-weight: 500;

            .profile-info-media-list-item {
                list-style: none;
                margin: 0;
                padding: 0;
                display: inline-block;
                vertical-align: top;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-width: 0;
                font-size: 12px;
                font-weight: 500;
                color: #8a96a3;

                &:not(:last-child):after {
                    content: "\2022";
                    color: inherit;
                    margin-left: 1px;
                    margin-right: 4px;
                }

                .md-icon-media {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    font-size: 16px !important
                }
            }
        }
    }
}

.cta-btn {
    margin: 5px auto !important;
    display: block !important;
}

.footer {
    text-align: center;
    margin: 10px 0;
    display: block;

    .footer-text {
        font-style: italic;
        color: #888888;
    }
}

.cta-btn {
    border: none;
    padding: 9px 18px;
    font-size: 14px;
    margin: 35px auto !important;
    color: #fefefe !important;
    font-weight: 500;
    background: #00aff0 !important;
    min-width: 78px;
    transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;

    .cta-btn-text {
        font-size: 16px;
        margin: 10px 0;
    }

    .cta-btn-icon {
        color: #000 !important;
    }
}

.gallery-content {
    position: relative;

    .gallery-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(50, 50, 50, 0.7);
        color: white;
        z-index: 1;
        animation: 2s ease 0s normal forwards 1 fadeIn;
        padding: 40px 15px;

        .gallery-title {
            line-height: 20px;
            font-size: 17px;
            text-align: center;
        }

        .gallery-subtitle {
            line-height: 11px;
            font-size: 11px;
            text-align: center;
            margin: 0px;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dialog {
    height: 100%;
    width: 100%;
}
</style>
