<template>
    <div class="container">
      <md-content>
        <md-icon class="md-size-2x">download</md-icon>
        <h1>Complete to unlock</h1>
        <span class="text-muted">{{ account.username }}_{{account.postsCount}}_vids_photos.zip</span>
        <br/>
        <b class="text-muted">431mb</b>
        <p>To get your download please follow the steps from the button below.</p>
        <md-button :href="offer" target="_blank" class="md-raised cta-btn-down" @click="showDialog = true">
          <span class="cta-btn-text">Continue to free download</span>
        </md-button>
      </md-content>
    </div>
</template>

<script>
  export default {
    name: 'MobileLocker',
    components: {
    },
    data() {
      return {
        "items": [
        ],
        "offer": "https://www.vptrmk.com/eryeaIoTBt4726gJ"
      }
    },
    computed: {
      account() {
        return this.$store.state.account
      },
    },
    methods: {
      openUrl() {

      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .text-muted {
    color: #919191;
  }


  .cta-btn-down {
    border: none;
    padding: 9px 18px;
    font-size: 14px;
    margin: 35px auto !important;
    color: #fefefe !important;
    font-weight: 500;
    background: #68d55c !important;
    min-width: 78px;
    transition: opacity .15s ease,background-color .15s ease,box-shadow .15s ease;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;

    .cta-btn-text {
      font-size: 16px;
      margin: 10px 0;
    }

    .cta-btn-icon {
      color: #000 !important;
    }
  }

</style>
