<template>
  <div class="code-view">
    <pre>{{text}}</pre>
    <md-button @click="copy" class="md-raised md-primary code-copy">
      <md-icon>content_copy</md-icon>
    </md-button>
  </div>
</template>

<script>
  export default {
    name: 'Clipboard',
    props: {
      text: String
    },
    computed: {
      account() {
        return this.$store.state.account
      },
      postsCount() {
        let postsCount = this.account.postsCount
        if (postsCount === 0) {
          return this.photosCount + this.videosCount;
        }
        return postsCount;
      },
      photosCount() {
        let photosCount = this.account.photosCount
        if (photosCount === 0) {
          return 56;
        }
        return photosCount;
      },
      videosCount() {
        let videosCount = this.account.videosCount
        if (videosCount === 0) {
          return 31;
        }
        return videosCount;
      }
    },
    methods: {
      async copy() {
        await this.copyURL(this.text)
      },
      async copyURL(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
          alert('Copied');
        } catch($e) {
          alert('Cannot copy');
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .code-view {
    padding: 5px;
    background-color: #eeeeee;
    display: flex;
    pre {
      flex: 1 auto;
      overflow-x: scroll;
    }
    .code-copy {
      min-width: 40px;
    }
  }
</style>
