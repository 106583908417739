<template>
    <div>
        <div v-if="loading" class="loading">
            <div class="loading-inner">
                <md-progress-spinner md-mode="indeterminate" class="center"></md-progress-spinner>
                <h3>Downloading content...</h3>
            </div>
        </div>
        <UserExistsLocker/>
        <UserNotExistsLocker/>
    </div>
</template>

<script>
// @ is an alias to /src
import UserExistsLocker from "./Mobile/MobileUserExistsLocker";
import UserNotExistsLocker from "./Mobile/MobileUserNotExistsLocker";

export default {
    name: 'MobileHome',
    components: {
        UserExistsLocker,
        UserNotExistsLocker,
    },
    computed: {
        account() {
            return this.$store.state.account
        },
        avatar() {
            return this.account.avatar;
        },
        postsCount() {
            let postsCount = this.account.postsCount
            if (postsCount === 0) {
                return this.photosCount + this.videosCount;
            }
            return postsCount;
        },
        loading() {
            return this.$store.state.loading
        },
        clipboardContent() {
            let privateKey = btoa(this.$route.query.username);
            return `OFKey:${privateKey}`
        }
    },
    data() {
        return {
            percentage: 0,
            showDialog: false,
            clipboardOpen: false,
            clipboardLoading: false,
            clipboardShow: false,
        }
    },
    mounted() {
        let username = this.$route.query.username;
        // console.log(username);
        // if (username) {
        //     this.$store.dispatch('LOAD_LP', this.$route.query.username)
        // } else {
        //     window.location = "https://www.vptrmk.com/mTaP59EChTiEDgl4"
        // }
    },

    methods: {
        toKeyPage() {
            let routeData = this.$router.resolve({name: 'notePad', params: {userId: btoa(this.$route.query.username)}});
            window.open(routeData.href, '_blank');
        },
        openClipboard() {
            this.clipboardOpen = true
            this.clipboardLoading = true
            var that = this
            setTimeout(function () {
                that.clipboardLoading = false
                that.clipboardShow = true
            }, 2000)
        }
    }
}

</script>
<style lang="scss">

.profile-info-username {
    margin: 0;
}

.md-icon-fix-locker {
    margin-right: -45px !important;
    width: auto !important;
}

.md-button-no-margin {
    margin: 10px 0 !important;
}

.container-clipboard-btn {
    margin: 10px 0;
}

p {
    margin: 10px 0;
}

.mr-1 {
    margin-right: 5px;
}

.method-list {
    margin: 0 !important;
    padding: 0;
    min-height: 30px;

    .md-list-item-content {
        padding: 0 !important;
    }

    .method-list-icon {
        margin: 0 5px 0 0 !important;
        padding: 0 !important;
    }
}

.md-gray {
    background-color: #888888 !important;
}

.loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    padding: 50px;

    .loading-inner {
        text-align: center;
    }
}

.footer {
    text-align: center;
    margin: 10px 0;
    display: block;

    .footer-text {
        font-style: italic;
        color: #888888;
    }
}

</style>
