<template>
    <div class="video-item">
        <h2 class="video-title">{{ this.title }}</h2>
        <div class="video-player"
             v-bind:style="{ 'background-image': 'url(' + this.thumbnail + ')', 'background-size': 'cover' }">
            <div class="video-loader">
                <md-progress-spinner md-mode="indeterminate" class="center video-loader-spinner"></md-progress-spinner>
            </div>
            <div class="video-controls">
                <md-icon class="video-controls-play">play_arrow</md-icon>
                <div class="video-progress"></div>
                <div class="video-controls-duration">00:00 - {{ videoDuration }}</div>
            </div>
        </div>
        <div class="video-info">
            <span><b>Duration:</b> {{ fullDuration }} </span>
            <span><b>Resolution:</b> 720x1280 </span>
            <span><b>Format:</b> mp4 </span>
            <span><b>Size:</b> {{ size }} MB</span>
        </div>

    </div>
</template>

<script>
import Clipboard from "./Clipboard";

export default {
    name: 'VideoItem',
    components: {Clipboard},
    props: {
        title: String,
        thumbnail: String,
        duration: Number,
        size: String,
        resolution: String,
    },
    computed: {
        fullDuration() {
            return this.calculateHHMMSS(this.duration)
        },
        videoDuration() {
            return this.calculateMMSS(this.duration)
        }
    },
    methods: {
        calculateHHMMSS(val) {
            var sec_num = parseInt(val, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            return hours + ':' + minutes + ':' + seconds;
        },
        calculateMMSS(val) {
            var sec_num = parseInt(val, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            return minutes + ':' + seconds;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-item {
    padding: 0px 5px 15px 5px;

    .video-title {
        font-family: monospace;
        word-break: break-word;
        font-size: 12px;
        line-height: 12px;
        height: 25px;
        margin-bottom: 0px;
    }

    .video-player {
        overflow: hidden;
        height: 0;
        padding-top: 56.25%;
        position: relative;
        margin-bottom: 10px;
        border: 1px #ececec solid;

        .video-loader {
            position: absolute;
            top: calc(50% - 30px);
            left: calc(50% - 32.5px);

            .video-loader-spinner .md-progress-spinner-circle {
                color: gray !important;
                stroke: red !important;
            }
        }

        .video-controls {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            background-color: #34495e;
            display: flex;
            color: #ffffff;
            align-items: center;
            padding: 0px 10px;

            .video-controls-play {
                color: #ffffff;
                cursor: pointer;
            }

            .video-progress {
                flex: 1;
                background-color: white;
                margin: 0 10px;
                height: 3px;
            }

            .video-controls-duration {
                font-size: 13px;
            }
        }
    }

    .video-info {
        text-align: left;
        font-family: monospace;
        font-size: 10px;
        line-height: 12px;

        span {
            display: block;
        }
    }
}
</style>
