<template>
  <div v-if="isReady" class="container">
    <span class="md-title">View Leaked content of @{{ account.username }}</span>
    <p class="md-subheading">To view the leaks of @{{ account.username }} use one of the following options.</p>
    <md-divider></md-divider>
    <div class="content-center">
      <md-divider></md-divider>
      <md-list class="method-list">
        <md-list-item class="method-list-item">
          <md-icon class="method-list-icon md-primary">web</md-icon>
          <span class="md-list-item-text md-body-2">Web unlock</span>
          <md-badge class="md-square md-primary mr-1" md-content="⚡ Fastest"></md-badge>
          <md-badge class="md-square md-primary mr-1" md-content="100% Working"></md-badge>
          <md-badge class="md-square" md-content="New"/>
        </md-list-item>
      </md-list>
      <span class="md-body-1">To get access to all the downloads of <b>{{account.username}}</b> you need to complete <b>2 short tasks</b>. When you are done you will <b>automatically be redirect</b> to one of our <b>mega.co.nz</b> archives of {{account.username}}.</span>
      <br/>
      <md-button href="https://installchecker.com/cl/i/po5l5l" target="_blank" class="md-raised md-primary md-button-no-margin">
        <md-icon class="md-icon-fix-locker">locker</md-icon>
        Open tasks page
      </md-button>
      <br/>
      <p class="md-caption">It might take a little time before the tasks page recognize all the completed tasks. Please refresh the page if you are sure you completed <b>2 tasks</b>!</p>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import Locker from "@/components/Locker/Locker";
import OFGallery from "@/components/OFGallery";
import Clipboard from "../../components/Clipboard";

export default {
  name: 'mobileUserNotExistsLocker',
  components: {
    Clipboard,
    OFGallery,
    Locker
  },
  computed: {
    account() {
      return this.$store.state.account
    },
    avatar() {
      return this.account.avatar;
    },
    postsCount() {
      let postsCount = this.account.postsCount
      if (postsCount === 0) {
        return this.photosCount + this.videosCount;
      }
      return postsCount;
    },
    isReady() {
      return !this.$store.state.loading && !this.$store.state.account.userExists
    },
    clipboardContent() {
      let privateKey = btoa(this.$route.query.username);
      return `OFKey:${privateKey}`
    }
  },
  data() {
    return {
      percentage: 0,
      showDialog: false,
      clipboardOpen: false,
      clipboardLoading: false,
      clipboardShow: false,
    }
  },

  methods: {
    toKeyPage() {
      let routeData = this.$router.resolve({name: 'notePad', params: {userId: btoa(this.$route.query.username)}});
      window.open(routeData.href, '_blank');
    },
    openClipboard() {
      this.clipboardOpen = true
      this.clipboardLoading = true
      var that = this
      setTimeout(function () {
        that.clipboardLoading = false
        that.clipboardShow = true
      }, 2000)
    }
  }
}

</script>
<style scoped lang="scss">

.container {
  padding: 5px 10px;

  .profile-info-username {
    margin: 0;
  }
}

.md-icon-fix-locker {
  margin-right: -45px !important;
  width: auto !important;
}

.md-button-no-margin {
  margin: 10px 0 !important;
}

.container-clipboard-btn {
  margin: 10px 0;
}

p {
  margin: 10px 0;
}

.mr-1 {
  margin-right: 5px;
}

.method-list {
  margin: 0 !important;
  padding: 0;
  min-height: 30px;

  .md-list-item-content {
    padding: 0 !important;
  }

  .method-list-icon {
    margin: 0 5px 0 0 !important;
    padding: 0 !important;
  }
}

.md-gray {
  background-color: #888888 !important;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  padding: 50px;

  .loading-inner {
    text-align: center;
  }
}

.footer {
  text-align: center;
  margin: 10px 0;
  display: block;

  .footer-text {
    font-style: italic;
    color: #888888;
  }
}

</style>
