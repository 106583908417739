import Vue from 'vue'
import Vuex from 'vuex'
import Axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: true,
    found: false,
    account: {},
    country: 'US',
    username: "",
    userExists: false,
    subid: "",
    site: ""
  },
  mutations: {
    SET_COUNTRY_CODE: (state, payload) => {
      state.country = payload;
    },
    SET_ACCOUNT_INFO: (state, payload) => {
      state.account = payload;
    }
  },
  actions: {
    LOAD_LP: async ({dispatch, state, commit}, {username, subid, site}) => {
      state.loading = true

      state.subid = subid
      state.site = site
      state.username = username
      await dispatch("GET_USERINFO", {
        username,
        site
      })

      state.loading = false
    },
    OPEN_OFFER_PAGE: ({}) => {
      window.location = "https://abnrq.lncredlbiedate.com/c/da57dc555e50572d?s1=74171&s2=1582293&j1=1";
    },
    GET_USERINFO: async ({dispatch, commit}, {username, site}) => {
      let data = null;
      try {
        let {data} = await Axios.get(`https://api.xnlyfanx.com/api/account?username=${username}&site=${site}`)

        if (data.active) {
          commit('SET_ACCOUNT_INFO', {
            ...data.account,
            userExists: true
          })
          commit('SET_COUNTRY_CODE', data.country)
        } else {
          dispatch('OPEN_OFFER_PAGE')
        }

      } catch (err) {
        commit('SET_ACCOUNT_INFO', {
          username: username,
          userExists: false
        })
        commit('SET_COUNTRY_CODE', 'XX')
      }
    },

  },
  modules: {}
})
