<template>
    <div class="container-gallery">
      <OFGalleryItem v-for="item in this.items" class="item" :key="item.id" :image="item.image" :type="item.type"/>
    </div>
</template>

<script>
  import OFGalleryItem from '@/components/OFGalleryItem.vue'

  export default {
    name: 'OFGallery',
    components: {
      OFGalleryItem,
    },
    computed: {
      account() {
        return this.$store.state.account
      },
      avatar() {
        return this.account.avatar;
      },
      header() {
        return this.account.header;
      },
      items() {
        return [
          {
            "id": 13,
            "image": this.avatar,
            "type": "image"
          },
          {
            "id": 12,
            "image": this.header,
            "type": "image"
          },
          {
            "id": 1,
            "image": "acc/1.png",
            "type": "video"
          },
          {
            "id": 2,
            "image": "acc/2.png",
            "type": "image"
          },
          {
            "id": 3,
            "image": "acc/3.png",
            "type": "image"
          },
          {
            "id": 4,
            "image": "acc/4.png",
            "type": "video"
          },
          {
            "id": 5,
            "image": "acc/5.png",
            "type": "video"
          },
          {
            "id": 6,
            "image": "acc/6.png",
            "type": "video"
          },
          {
            "id": 7,
            "image": "acc/7.png",
            "type": "image"
          },
          {
            "id": 8,
            "image": "acc/8.png",
            "type": "image"
          },
          {
            "id": 9,
            "image": "acc/9.png",
            "type": "image"
          },
          {
            "id": 10,
            "image": "acc/10.png",
            "type": "latest"
          }
        ]
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .container-gallery {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-content: center;

      .item {
        flex: 1 1 auto;
        margin: 1px;
        min-width: 100px;
      }
    }
</style>
