<template>
    <div class="container-dialog">
        <DesktopLocker v-if="!this.isMobile()"/>
        <MobileLocker v-if="this.isMobile()"/>
    </div>
</template>

<script>
  import DesktopLocker from "./DesktopLocker";
  import MobileLocker from "./MobileLocker";

  export default {
    name: 'Locker',
    components: {
      DesktopLocker,
      MobileLocker,
    },
    data() {
      return {

      }
    },
    computed: {
      duration() {
        return ""
      }
    },
    mounted() {
    },
    methods: {
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .container-dialog {
      height: 100%;
      width: 100%;
      display: block;
      margin: 0 auto;
    }
</style>
