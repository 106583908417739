<template>
    <div>
        <div v-if="loading" class="loading">
            <div class="loading-inner">
                <md-progress-spinner md-mode="indeterminate" class="center"></md-progress-spinner>
                <h3>Downloading content...</h3>
            </div>
        </div>
        <div v-if="!loading">
            <div class="profile-container">
                <div class="profile-header"
                     v-bind:style="{ 'background-image': 'url(' + account.header + ')', 'background-size': 'cover' }">
                </div>
                <img class="profile-image" :src="avatar"/>
            </div>
            <div class="profile-info-container">
                <span class="md-title">{{ account.name }}</span>
                <p class="md-subheading">@{{ account.username }}</p>
            </div>
            <div class="video-show-actions" v-if="!showVideos">
                <p class="md-body-1">Our server has found <b>{{ postsCount }} LEAKED VIDEOS</b> of
                    <b>{{ account.username }}</b>. To watch the videos click on the button below. </p>
                <md-button @click="openVideos" class="md-raised md-primary code-copy">See {{ postsCount }} VIDEOS
                </md-button>
            </div>
            <div v-if="isVideosLoading" class="videos-loading">
                <div class="loading-inner">
                    <span class="md-body-2 videos-loading-text">Fetching {{ postsCount }} Videos from server...</span>
                    <md-progress-bar md-mode="indeterminate" class="progress-bar-captcha"></md-progress-bar>
                </div>
            </div>
            <div v-if="isVideosReady">
                <div class="md-layout">
                    <div class="md-layout-item md-size-50" v-for="item in this.items" :key="item.id">
                        <VideoItem :duration="item.duration" :thumbnail="item.thumbnail" :size="item.size"
                                   :title="item.title" :resolution="item.resolution"></VideoItem>
                    </div>

                    <div class="md-layout-item md-size-100 text-center">
                        <md-button class="md-raised md-primary code-copy">Load more</md-button>
                    </div>
                </div>


            </div>

        </div>
        <md-dialog :md-active.sync="showDialogA" :md-close-on-esc="false" :md-click-outside-to-close="false"
                   :md-fullscreen="false" class="dialog-signup">
            <md-dialog-title>Account Verification Required!</md-dialog-title>
            <md-dialog-content class="dialog-content">
                To be able to watch <b>{{ account.username }} LEAKED videos for free</b> you need to have a verified
                account at one of our verification partners. You can do this by clicking on the button "<i>Generate
                Verify Link + Code</i>" to
                generate your unique verification link, you will be asked to pay <b>a one time fee of 1$</b>.
                <br/>
                <br/>
                After verification our verification partner will sent you to the page where you can view all the leaked
                content based on your unique code. You will be able to see more then <b>{{ postsCount }} leaks of
                {{ account.username }}</b> and
                other OnlyFans models for free.
                <br/>
                <br/>
                <br/>
                <div v-if="(!this.userCodeGenerated && !this.userCodeLoading)" class="dialog-button-holder">
                    <md-button @click="generateUserCode" class="md-raised md-primary code-copy">Generate Verify link +
                        code
                    </md-button>
                </div>
                <div v-if="isUserCodeLoading">
                    <md-progress-bar md-mode="indeterminate" class="progress-bar-captcha"></md-progress-bar>
                </div>
                <div v-if="isUserCodeGenerated">
                    <p class="md-body-2">Please copy this code as you will need it while doing the verification!</p>
                    <Clipboard :text="userGeneratedCode"/>
                    <br/>
                    <br/>
                    <md-button @click="openLocker" class="md-raised md-primary">Open verification page</md-button>
                    <br/>
                    <br/>
                </div>
                <!--                To go to the verify page click on <b>"Verify"</b>. After you're done you will be redirected-->
                <!--                automatically to the download page.-->
            </md-dialog-content>
        </md-dialog>

        <md-dialog :md-active.sync="showDialogB" :md-close-on-esc="false" :md-click-outside-to-close="false"
                   :md-fullscreen="false" class="dialog-signup">
            <md-dialog-content class="dialog-content-signup">
<!--                <div class="signup-benefits-badge-holder">-->
<!--                    <span class="signup-badge">Only 1$</span>-->
<!--                </div>-->
                <div class="signup-content">
                    <div class="text-center">
                        <span class="md-subheading">To see all the <b>{{ postsCount }}</b> leaks of </span>
                        <b class="md-title signup-benefits-account-title">@{{ account.username }}</b>
                        <span class="md-subheading">You <b>first</b> need to register.</span>
                    </div>
                    <div class="signup-benefits-container">
                        <div class="signup-user">
                            <img :src="account.avatar"/>
                        </div>
                        <div class="signup-benefits">
                            <md-list>
                                <md-subheader>Benefits</md-subheader>
                                <md-list-item class="benefit-item">
                                    <div class="md-list-item-text">
                                        <span class="benefit-title">420123+ OnlyFans Models</span>
                                        <span
                                            class="benefit-description">Free to browse through all leaked content</span>
                                    </div>

                                    <md-button class="md-icon-button md-list-action">
                                        <md-icon>people_outline</md-icon>
                                    </md-button>
                                </md-list-item>
                                <md-list-item class="benefit-item">
                                    <div class="md-list-item-text">
                                        <span class="benefit-title">Always up-to-date</span>
                                        <span class="benefit-description">Content will be updated daily</span>
                                    </div>

                                    <md-button class="md-icon-button md-list-action">
                                        <md-icon>update</md-icon>
                                    </md-button>
                                </md-list-item>
                                <md-list-item class="benefit-item">
                                    <div class="md-list-item-text">
                                        <span class="benefit-title">Invite only</span>
                                        <span
                                            class="benefit-description">Only a limited amount of people will have access</span>
                                    </div>

                                    <md-button class="md-icon-button md-list-action">
                                        <md-icon>lock_open</md-icon>
                                    </md-button>
                                </md-list-item>
                            </md-list>
                        </div>
                    </div>
                    <p>For only <b>1$</b> you can see all the leaks of <b>@{{ account.username }}</b> and many more!</p>
                    <p>To be able to watch <b>{{ account.username }} LEAKED videos</b> you first need to create an
                        account.
                        Because we have to maintain our servers and of course pay our memberships at all the OnlyFans
                        creators we ask for a little fee.</p>

                    <p>To create an account click on the button "<i>Generate Invite Code + Link</i>". This will generate
                        an
                        unique code which is <b>only for you</b>.
                        Please save this code somewhere so you can get access to our portal any time you want.</p>

                    <p>When you have your unique code please copy it and proceed to the Sign Up page, after you are done
                        signing up and paying the 1$ fee, you will get an <b>email with the link to our portal</b>. Here
                        you
                        need to enter your code everytime again to access it.</p>
                    <div class="text-center">
                        <div v-if="(!this.userCodeGenerated && !this.userCodeLoading)" class="dialog-button-holder">
                            <md-button @click="generateUserCode" class="md-raised md-primary code-copy">Generate Invite
                                Code
                                +
                                link
                            </md-button>
                        </div>
                        <div v-if="isUserCodeLoading">
                            <md-progress-bar md-mode="indeterminate" class="progress-bar-captcha"></md-progress-bar>
                        </div>
                        <div v-if="isUserCodeGenerated">
                            <p class="md-body-2">Please copy & save this code as you will need it to access the
                                leaks!</p>
                            <Clipboard :text="userGeneratedCode"/>
                            <p class="md-caption">Note: please don't share this code with anybody as we will <b>track IP
                                usage</b>.</p>
                            <md-button @click="openLockerB" class="md-raised md-primary">Open register page</md-button>
                        </div>
                    </div>
                    <!--                To go to the verify page click on <b>"Verify"</b>. After you're done you will be redirected-->
                    <!--                automatically to the download page.-->
                </div>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import VideoItem from "../components/VideoItem";
import Clipboard from "../components/Clipboard";

export default {
    name: 'DesktopHome',
    components: {
        VideoItem,
        Clipboard
    },
    computed: {
        items() {
            return [
                {
                    id: 9,
                    thumbnail: "acc/9.png",
                    duration: 19,
                    size: "17.34",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_001.mp4`,
                },
                {
                    id: 10,
                    thumbnail: "acc/10.png",
                    duration: 5,
                    size: "5.34",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_002.mp4`,
                },
                {
                    id: 1,
                    thumbnail: "acc/1.png",
                    duration: 11,
                    size: "9.6",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_003.mp4`,
                },
                {
                    id: 2,
                    thumbnail: "acc/2.png",
                    duration: 23,
                    size: "12.34",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_004.mp4`,
                },
                {
                    id: 3,
                    thumbnail: "acc/3.png",
                    duration: 12,
                    size: "10.84",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_005.mp4`,
                },
                {
                    id: 4,
                    thumbnail: "acc/4.png",
                    duration: 223,
                    size: "49.33",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_006.mp4`,
                },
                {
                    id: 5,
                    thumbnail: "acc/5.png",
                    duration: 503,
                    size: "132.24",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_007.mp4`,
                },
                {
                    id: 6,
                    thumbnail: "acc/6.png",
                    duration: 53,
                    size: "19.34",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_008.mp4`,
                },
                {
                    id: 7,
                    thumbnail: "acc/7.png",
                    duration: 15,
                    size: "16.54",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_009.mp4`,
                },
                {
                    id: 8,
                    thumbnail: "acc/8.png",
                    duration: 23,
                    size: "13.74",
                    resolution: "720x1280",
                    title: `${this.$store.state.account.username}_OnlyFans_Video_010.mp4`,
                }
            ]
        },
        account() {
            return this.$store.state.account
        },
        avatar() {
            return this.account.avatar;
        },
        loading() {
            return this.$store.state.loading
        },
        postsCount() {
            let postsCount = this.account.postsCount
            if (postsCount < 58) {
                return this.photosCount + this.videosCount;
            }
            return postsCount;
        },
        photosCount() {
            let photosCount = this.account.photosCount
            if (photosCount <= 49) {
                return 49;
            }
            return photosCount;
        },
        videosCount() {
            let videosCount = this.account.videosCount
            if (videosCount <= 32) {
                return 32;
            }
            return videosCount;
        },
        isVideosReady() {
            return this.showVideos && this.videosLoaded
        },
        isVideosLoading() {
            return this.showVideos && !this.videosLoaded
        },
        isUserCodeGenerated() {
            return this.userCodeGenerated && !this.userCodeLoading
        },
        isUserCodeLoading() {
            return !this.userCodeGenerated && this.userCodeLoading
        },
        offerUrl() {
            return `https://cdn.purflix.club/r/3e9pKx/?theme=unlock&s1=OF-001&s2=${this.$store.state.site}&s3=${this.$store.state.account.username}`
        },
        offerUrlB() {
            return `https://cdn.purflix.club/r/3e9pKx/?theme=unlock&s1=OF-002&s2=${this.$store.state.site}&s3=${this.$store.state.account.username}`
        },
        showDialogA() {
            if (!this.A_test && !this.B_test) this.A_B_test();
            return this.A_test && this.showDialog
        },
        showDialogB() {
            if (!this.A_test && !this.B_test) this.A_B_test();
            return this.B_test && this.showDialog
        }
    },
    data() {
        return {
            showVideos: false,
            videosLoaded: false,
            showDialog: false,
            userCodeGenerated: false,
            userCodeLoading: false,
            userGeneratedCode: "",
            A_test: false,
            B_test: false,
        }
    },
    methods: {
        toKeyPage() {
            let routeData = this.$router.resolve({name: 'notePad', params: {userId: btoa(this.$route.query.username)}});
            window.open(routeData.href, '_blank');
        },
        openVideos() {
            this.showVideos = true;
            this.loadVideos()
        },
        loadVideos() {
            this.showVideos = true;
            this.videosLoaded = false;
            var that = this
            setTimeout(function () {
                that.showVideos = true;
                that.videosLoaded = true;
                that.startLocker()
            }, 2000)
        },
        startLocker() {
            var that = this
            setTimeout(function () {
                that.showDialog = true
            }, 4000)
        },
        openLocker: function (event) {
            window.open(this.offerUrl, '_blank');
            // window.location =
        },
        openPopup: function ({url, title, w, h}) {
            // Fixes dual-screen position                         Most browsers      Firefox
            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            // Puts focus on the newWindow
            if (window.focus) {
                newWindow.focus();
            }
        },
        openLockerB: function (event) {
            window.open(this.offerUrlB, '_blank');

            // var w = 1200;
            // var h = 800;
            // // this.openPopup(this.offerUrlB, 'RegisterPage', w, h)
            // var newWindow = window.open(this.offerUrlB, 'RegisterPage', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h);
            //
            // if (window.focus) {
            //     newWindow.focus()
            // }
        },
        generateUserCode() {
            this.userCodeLoading = true
            var that = this
            setTimeout(function () {
                that.userCodeGenerated = true;
                that.userCodeLoading = false;
                that.userGeneratedCode = that.generateUniqueCode(40);
            }, 1000)
        },
        generateUniqueCode(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
        A_B_test() {
            const type = Math.random() < 0.5;
            this.A_test = type;
            this.B_test = !type;
            // console.log(type)
            // this.A_test = true;
        },
    }
}

</script>
<style scoped lang="scss">
.video-show-actions {
    text-align: center;
    padding-bottom: 30px;
}

.text-center {
    text-align: center;
    margin-bottom: 15px;
}

.md-icon-fix-locker {
    margin-right: -45px !important;
    width: auto !important;
}

.md-title {
    font-size: 24px !important;
}

.md-subheading {
    font-size: 18px !important;
    margin-top: 0px;
    margin-bottom: 15px;
}

.md-button-no-margin {
    margin: 10px 0 !important;
}

.container-clipboard-btn {
    margin: 10px 0;
}

p {
    margin: 10px 0;
}

.mr-1 {
    margin-right: 5px;
}

.videos-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0;
    padding: 30px 0px 50px;

    .videos-loading-text {
        margin-bottom: 10px;
        display: block;
    }
}

.profile-container {
    background: white;
    position: relative;
    margin-bottom: 70px;
    border-radius: 5px 5px 0 0;

    .profile-header {
        height: 200px;
        border-radius: 5px 5px 0 0;
    }

    .profile-image {
        position: absolute;
        width: 140px;
        height: 140px;
        left: calc(50% - 70px);
        margin-top: -70px;
        display: block;
        z-index: 1;
        border-radius: 100%;
        border: 4px solid #ffffff;
        font-size: 36px;
    }
}

.loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    padding: 50px;

    .loading-inner {
        text-align: center;
    }
}

.profile-info-container {
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
    text-align: center;

    .profile-info-name,
    .profile-info-username {
        font-size: 15px;
        margin: 0;
    }


    .profile-info-name {
    }

    .profile-info-username {
        font-weight: 400;
    }


    .profile-info-media {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2px;

        .profile-info-media-list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
            font-size: 12px;
            font-weight: 500;

            .profile-info-media-list-item {
                list-style: none;
                margin: 0;
                padding: 0;
                display: inline-block;
                vertical-align: top;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                min-width: 0;
                font-size: 12px;
                font-weight: 500;
                color: #8a96a3;

                &:not(:last-child):after {
                    content: "\2022";
                    color: inherit;
                    margin-left: 1px;
                    margin-right: 4px;
                }

                .md-icon-media {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    font-size: 16px !important
                }
            }
        }
    }
}

.signup-content {
    overflow-y: auto;
}
.signup-benefits-badge-holder {
    position: absolute;
    right: -20px;
    top: -10px;
    z-index: 99999;

    .signup-badge {
        width: auto;
        border-radius: 3px;
        height: 18px;
        padding: 8px 14px;
        background-color: var(--md-theme-default-accent);
        color: white;
        font-size: 27px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.signup-benefits-account-title {
    display: block;
    line-height: 40px;
}

.signup-benefits-container {
    display: flex;

    .signup-user {
        width: 40%;
        display: flex;
        padding: 0 50px;

        img {
            display: flex;
            flex: 1;
        }
    }

    .signup-benefits {
        flex: 1 auto;

        .benefit-item {
            padding: 5px 0px;

            .benefit-title {
                font-weight: bold;
                font-size: 18px;
            }

            .benefit-description {
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
}
</style>
