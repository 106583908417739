<template>
  <div class="gallery-item" v-bind:style="{ 'background-image': 'url(' + image + ')' }">
    <div class="gallery-item-type">
      <div v-if="this.type === 'latest'" class="gallery-item-text" >
        <span>{{ postsCount }}+</span>
      </div>
      <i v-if="this.type === 'video'" aria-hidden="true" class="fa fa-play icon"></i>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OFGalleryItem',
    props: {
      image: String,
      type: String
    },
    computed: {
      account() {
        return this.$store.state.account
      },
      postsCount() {
        let postsCount = this.account.postsCount
        if (postsCount === 0) {
          return this.photosCount + this.videosCount;
        }
        return postsCount;
      },
      photosCount() {
        let photosCount = this.account.photosCount
        if (photosCount === 0) {
          return 56;
        }
        return photosCount;
      },
      videosCount() {
        let videosCount = this.account.videosCount
        if (videosCount === 0) {
          return 31;
        }
        return videosCount;
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .gallery-item {
    display: block;
    color: #6c757d;
    background-size: cover;
    background-position: center center;
    position: relative;

    &:before {
      display: block;
      padding-top: 100%;
      content: "";
    }

    .gallery-item >* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .gallery-item-type {
      position: absolute;
      top:0;
      left:0;
      right: 0;
      bottom: 0;

      .icon {
        top: 4px;
        right: 4px;
        position: absolute;
        font-size: 18px;
        color: rgba(255,255,255,0.5);
      }

      .gallery-item-text {
        text-align: center;
        position: absolute;
        top: calc(50% - 10px);
        line-height: 20px;
        left: 0;
        right: 0;
        font-size: 20px;
        color:white;
      }
    }
  }
</style>
